import React from 'react'

const Projects = () => {
  return (
    <div>
        <div class="breadcumb-wrapper" data-bg-src="assets/img/bg/breadcumb-bg.jpg"
         style={{
            backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')",
            backgroundSize: "cover",
             height:'10vh'
          }}
        >
      <div class="container">
        <div class="breadcumb-content">
          <h1 class="breadcumb-title">Our Projects</h1>
          <ul class="breadcumb-menu">
            <li><a href="/">Home</a></li>
            <li>Our Projects</li>
          </ul>
        </div>
      </div>
    </div>
    <section class="space">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_1.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p1"></div>
                  <h3 class="box-title">
                    <a href="#">IT Consultency</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_1.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_2.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p2"></div>
                  <h3 class="box-title">
                    <a href="#">Web Development</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_2.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_3.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p3"></div>
                  <h3 class="box-title">
                    <a href="#">Website Design</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_3.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_4.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p4"></div>
                  <h3 class="box-title">
                    <a href="#">SEO Optimization</a>
                  </h3>
                  <p class="project-subtitle">Marketing</p>
                  <a
                    href="assets/img/project/project_1_4.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_5.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p5"></div>
                  <h3 class="box-title">
                    <a href="#">Digital Marketing</a>
                  </h3>
                  <p class="project-subtitle">Marketing</p>
                  <a
                    href="assets/img/project/project_1_5.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_6.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p6"></div>
                  <h3 class="box-title">
                    <a href="#">Business Analysis</a>
                  </h3>
                  <p class="project-subtitle">Business</p>
                  <a
                    href="assets/img/project/project_1_6.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_7.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p7"></div>
                  <h3 class="box-title">
                    <a href="#">Software Service</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_7.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_8.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p8"></div>
                  <h3 class="box-title">
                    <a href="#">Data Recovery</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_8.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="project-card">
              <div class="project-img">
                <img
                  src="assets/img/project/project_1_9.jpg"
                  alt="project image"
                />
              </div>
              <div class="project-content-wrap">
                <div class="project-content">
                  <div class="box-particle" id="project-p9"></div>
                  <h3 class="box-title">
                    <a href="#">Cloud Computing</a>
                  </h3>
                  <p class="project-subtitle">Technology</p>
                  <a
                    href="assets/img/project/project_1_9.jpg"
                    class="icon-btn popup-image"
                    ><i class="far fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape-mockup" data-top="0%" data-right="0%">
        <img src="assets/img/shape/tech_shape_1.png" alt="shape" />
      </div>
      <div class="shape-mockup" data-bottom="0%" data-left="0%">
        <img src="assets/img/shape/tech_shape_2.png" alt="shape" />
      </div>
    </section>
    </div>
  )
}

export default Projects