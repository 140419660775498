import React from 'react'
import Hero from './Hero'
import Services from './Services'
import Counter from './Counter'
import About from './About'
import Team from './Team'
import Choose from './Choose'
import CaseStudies from './CaseStudies'
import WorkWithUs from './WorkWithUs'
import Brand from './Brand'
import CustomerFeedback from './CustomerFeedback'
import Blog from './Blog'

const Home = () => {
  return (
    <div>
         <Hero/>
      <Services/>
      <Counter/>
      <About/>
      {/* <Team/> */}
      <Choose/>
      <CaseStudies/>
      <WorkWithUs/>
      {/* <Brand/> */}
      {/* <CustomerFeedback/> */}
      {/* <Blog/> */}
    </div>
  )
}

export default Home