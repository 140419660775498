import React from 'react'

const About = () => {
  return (
    <div>
          <div
      class="about-area3 overflow-hidden"
      id="about-sec"
      data-pos-for=".team-area6"
      data-sec-pos="bottom-half"
    >
      <div class="container th-container4">
        <div class="row">
          <div class="col-xl-6">
            <div class="pe-xl-5 me-xl-3">
              <div class="title-area style2 mb-30">
                <span class="sub-title sub-title4 style1">Who We Are</span>
                <h2 class="sec-title">
                  We Deliver Consumer centric Tech Solutions.
                </h2>
              </div>
              <p class="mt-n2 mb-25">
              Zeus Crew stay abreast of emerging technologies and industry
                trends to remain competitive and provide innovative solutions to
                their clients. This includes trends such as artificial
                intelligence, machine learning, Internet of Things (IoT),
                blockchain, edge computing, and 5G networks.
              </p>
              <div class="checklist style7">
                <ul>
                  <li>
                    <i class="far fa-check-circle"></i>Identify growth
                    opportunities and expand market presence Enhance
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i> operational efficiency
                    and reduce waste
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>Make informed decisions
                    based on data-driven insights
                  </li>
                </ul>
              </div>
              <div class="about-btn mt-35">
                <a
                  href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                  class="th-btn popup-video style-radius text-capitalize"
                  ><i class="fa-solid fa-circle-play me-2"></i>View Client
                  Stories</a
                >
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="img-box9">
              <div class="img1">
                <img src="assets/img/normal/about_11_1.jpg" alt="About" />
              </div>
              <div class="img2">
                <img src="assets/img/normal/about_11_2.jpg" alt="About" />
              </div>
              <div class="img3">
                <img src="assets/img/normal/about_11_3.jpg" alt="About" />
              </div>
              <div class="th-experience">
                <div class="th-experience_content">
                  <h2 class="experience-year">
                    <span class="counter-number">25</span>
                  </h2>
                  <p class="experience-text">
                    Years of experience in startup building
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default About