import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="footer-wrapper footer-layout5 footer-layout11"   style={{
            backgroundImage: "url('assets/img/bg/footer_bg_17.jpg')",
            backgroundSize: "contain",
          }}>
        <div class="container th-container4"
       
        >
          <div class="footer-top">
            <div class="row gx-0 align-items-center">
              <div class="col-xl">
                <div class="footer-newsletter">
                  <div class="footer-newsletter-content">
                    <h2 class="newsletter-title">Subscribe for daily update</h2>
                  </div>
                  <form class="newsletter-form">
                    <i class="fa-sharp fa-light fa-envelope"></i>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Email Address"
                      required=""
                    />
                    <button type="submit" class="th-btn">
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="widget-area">
            <div class="row justify-content-between">
              <div class="col-md-6 col-xxl-3 col-xl-4">
                <div class="widget footer-widget">
                  <h3 class="widget_title style2 text-white">About Company</h3>
                  <div class="th-widget-about style2">
                    <p class="about-text">
                    Zeus Crew is a technology solutions provider offering expertise in software development, IT management, and data analytics to drive business growth.
                    </p>
                    <div class="th-social">
                      <a href="https://www.facebook.com/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.twitter.com/">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="https://www.linkedin.com/">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="https://www.youtube.com/">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title style2 text-white">Address</h3>
                  <div class="th-widget-about">
                    <div class="footer-info">
                      <p class="footer-info_text">
                        <a href="tel:+9159008855" class="text-inherit">
                          +91 9876543210
                        </a>
                      </p>
                    </div>
                    <p class="footer-info">
                      <a class="text-inherit" href="mailto:info@zeuscrew.in">
                        info@zeuscrew.in
                      </a>
                    </p>
                    <p class="footer-info">5Th Floor, Rao Tower, Neil Rao Towers, Rd.No 3, <br/>Vijayanagar, EPIP Zone Phase 1, Whitefeild, Bangalore-560066 </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget widget_nav_menu footer-widget">
                  <h3 class="widget_title style2 text-white">Company</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu">
                      <li>
                        <a href="#">What we Offer</a>
                      </li>
                      <li>
                        <a href="#">Our Story</a>
                      </li>
                      <li>
                        <a href="#">Latest Posts</a>
                      </li>
                      <li>
                        <a href="#">Help Center</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div class="col-md-6 col-xl-auto">
                <div class="widget widget_offer footer-widget">
                  <h3 class="widget_title style2 text-white">Get the app</h3>
                  <p class="footer-text mb-30">We suggest connecting apps</p>
                  <div class="download-btn-wrap">
                    <div class="mb-10">
                      
                    </div>
                    <div>
                      
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div class="copyright-wrap">
          <div class="container th-container4">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-6">
                <p class="copyright-text">
                  Copyright <i class="fal fa-copyright"></i> 2024
                  <a href="/">Zeus Crew</a>
                  . All Rights Reserved.
                </p>
              </div>
              <div class="col-lg-6 text-center text-md-end">
                <ul class="footer-menu">
                  <li>
                    <a href="#">service</a>
                  </li>
                  <li>
                    <a href="#">about</a>
                  </li>
                  <li>
                    <a href="#">Articles</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="shape-mockup" data-top="0%" data-left="0%">
          <img src="assets/img/shape/footer_shape_7.png" alt="shape" />
        </div> */}
        {/* <div
          class="shape-mockup d-none d-xl-block"
          data-top="0%"
          data-right="0%"
        >
          <img src="assets/img/shape/footer_shape_2.png" alt="shape" />
        </div> */}
      </footer>
      <div class="scroll-top">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            transition: "stroke-dashoffset 10ms linear 0s",
            strokeDasharray: "307.919, 307.919",
            strokeDashoffset: 307.919
          }}
          
        ></path>
      </svg>
    </div>
    </div>
  );
};

export default Footer;
