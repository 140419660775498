import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import { Navigation } from "swiper/modules"; // Import Swiper navigation module
import "swiper/css"; // Import Swiper core styles
import "swiper/css/navigation"; // Import navigation styles

const CaseStudies = () => {
  return (
    <div>
      <section className="overflow-hidden space" id="project-sec">
        <div className="container th-container4">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="title-area text-center">
                <span className="sub-title sub-title4">Case Studies</span>
                <h2 className="sec-title">Discover Our Latest Projects</h2>
              </div>
            </div>
          </div>
          <div className="slider-area">
            <Swiper
              modules={[Navigation]} // Include the Navigation module
              navigation={true} // Enable navigation buttons
              loop={true} // Enable infinite loop
              slidesPerView={3} // Default number of slides visible
              spaceBetween={20} // Space between slides
              breakpoints={{
                0: { slidesPerView: 1 }, // 1 slide on small screens
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 }, // 2 slides on medium screens
                992: { slidesPerView: 2 },
                1200: { slidesPerView: 3 }, // 3 slides on larger screens
              }}
              className="swiper"
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div className="project-box style2">
                  <div className="project-img">
                    <img
                      src="assets/img/project/project_7_1.jpg"
                      alt="project image"
                    />
                  </div>
                  <div className="project-box_content">
                    <p className="project-box_desc">Networking</p>
                    <h3 className="box-title">
                      <a href="#">Cloud Security Service</a>
                    </h3>
                  </div>
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div className="project-box style2">
                  <div className="project-img">
                    <img
                      src="assets/img/project/project_7_2.jpg"
                      alt="project image"
                    />
                  </div>
                  <div className="project-box_content">
                    <p className="project-box_desc">Networking</p>
                    <h3 className="box-title">
                      <a href="#">Data Analytics System</a>
                    </h3>
                  </div>
                </div>
              </SwiperSlide>

              {/* Slide 3 */}
              <SwiperSlide>
                <div className="project-box style2">
                  <div className="project-img">
                    <img
                      src="assets/img/project/project_7_3.jpg"
                      alt="project image"
                    />
                  </div>
                  <div className="project-box_content">
                    <p className="project-box_desc">Solution</p>
                    <h3 className="box-title">
                      <a href="#">
                        IT Solution & Consultancy
                      </a>
                    </h3>
                  </div>
                </div>
              </SwiperSlide>

              {/* Add more SwiperSlides here as needed */}
            </Swiper>
          </div>
        </div>
        <div className="sec-shape style2 mt-5 pt-3">
          <a href="#" className="th-btn style-radius">
            All Case Studies
          </a>
        </div>
      </section>
    </div>
  );
};

export default CaseStudies;
