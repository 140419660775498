import React from 'react'

const Choose = () => {
  return (
    <div>
          <div
      class="choose-area2 overflow-hidden"
      data-bg-src="assets/img/bg/choose_bg_1.jpg"
      style={{
        backgroundImage: "url('assets/img/bg/choose_bg_1.jpg')",
        backgroundSize: "cover",
        // backgroundPosition: "center",
      }}
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-7">
            <div class="choose-image2">
              <div class="img1">
                <img src="assets/img/normal/choose_2.jpg" alt="Choose" />
              </div>
              {/* <div class="video-box4">
                <a
                  href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                  class="play-btn popup-video"
                  ><i class="fa-sharp fa-solid fa-play"></i
                ></a>
              </div> */}
            </div>
          </div>
          <div class="col-xl-5">
            <div class="title-area mb-30">
              <span class="sub-title sub-title4 style1">Why choose us</span>
              <h2 class="sec-title text-white">
                The Role of an Zeus Crew in Adaptation and Growth
              </h2>
            </div>
            <p class="choose-text mb-30">
            Zeus Crew adhere to stringent quality assurance standards and
              regulatory compliance requirements to ensure the reliability,
              security, and performance of their products and services.
            </p>
            <div class="accordion-area style6 accordion" id="faqAccordion">
              <div class="accordion-card style6">
                <div class="accordion-header" id="collapse-item-1">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1"
                    aria-expanded="false"
                    aria-controls="collapse-1"
                  >
                    Dedicated Teams
                  </button>
                </div>
                <div
                  id="collapse-1"
                  class="accordion-collapse collapse"
                  aria-labelledby="collapse-item-1"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body">
                    <p class="faq-text">
                    Build your projects with skilled, dedicated teams tailored to your business needs.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-card style6 active">
                <div class="accordion-header" id="collapse-item-2">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-2"
                    aria-expanded="true"
                    aria-controls="collapse-2"
                  >
                    CERTIFIED PROFESSIONAL
                  </button>
                </div>
                <div
                  id="collapse-2"
                  class="accordion-collapse collapse show"
                  aria-labelledby="collapse-item-2"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body">
                    <p class="faq-text">
                    Partner with certified professionals to ensure expertise and excellence in every solution.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-card style6">
                <div class="accordion-header" id="collapse-item-3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-3"
                    aria-expanded="false"
                    aria-controls="collapse-3"
                  >
                    24/7 SUPPORT TEAM
                  </button>
                </div>
                <div
                  id="collapse-3"
                  class="accordion-collapse collapse"
                  aria-labelledby="collapse-item-3"
                  data-bs-parent="#faqAccordion"
                >
                  <div class="accordion-body">
                    <p class="faq-text">
                    Our 24/7 support team is always available to assist you with prompt and reliable solutions.







                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Choose