import React from 'react'

const Services = () => {
  return (
    <div>
        <section>
        <div class="breadcumb-wrapper" data-bg-src="assets/img/bg/breadcumb-bg.jpg"
        style={{
            backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')",
            backgroundSize: "cover",
             height:'10vh'
          }}>
      <div class="container">
        <div class="breadcumb-content">
          <h1 class="breadcumb-title">Services</h1>
          <ul class="breadcumb-menu">
            <li><a href="/">Home</a></li>
            <li>Services</li>
          </ul>
        </div>
      </div>
    </div>
        </section>

        <section class="space" id="service-sec">
      <div class="container">
        <div class="row gy-4">
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">01</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Web Development</a>
              </h3>
              <p class="service-card_text">
              We provide custom web development solutions to create user-friendly, responsive, and high-performing websites tailored to your business needs.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">02</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_2.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">UI/UX Design</a>
              </h3>
              <p class="service-card_text">
               
We craft intuitive and engaging UI/UX designs that enhance user experience and drive customer satisfaction across digital platforms.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">03</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Digital Marketing</a>
              </h3>
              <p class="service-card_text">
              We provide comprehensive digital marketing strategies that boost online visibility, drive traffic, and increase conversions for businesses.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">04</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_4.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Business Analysis</a>
              </h3>
              <p class="service-card_text">
              We offer expert business analysis services to optimize processes, improve efficiency, and support data-driven decision-making for business growth.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">05</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_5.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Software Services</a>
              </h3>
              <p class="service-card_text">
              We provide innovative software services to deliver custom solutions that enhance business operations and drive digital transformation.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">06</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_6.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Machine Learning</a>
              </h3>
              <p class="service-card_text">
              We leverage machine learning to create intelligent systems that automate processes, enhance decision-making, and unlock valuable insights from data.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">07</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_7.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">IT Management</a>
              </h3>
              <p class="service-card_text">
              We provide expert IT management services to optimize your infrastructure, ensuring efficiency, security, and seamless business operations.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">08</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_8.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Cloud Computing</a>
              </h3>
              <p class="service-card_text">
              We offer cloud computing solutions for the clients that enhance scalability, flexibility, and cost-efficiency for your business management.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4">
            <div class="service-card">
              <div class="service-card_number">09</div>
              <div class="shape-icon">
                <img src="assets/img/icon/service_card_9.svg" alt="Icon" />
                <span class="dots"></span>
              </div>
              <h3 class="box-title">
                <a href="#">Backup & Recovery</a>
              </h3>
              <p class="service-card_text">
              We provide reliable backup and recovery solutions to ensure your data is safe and can be quickly restored in case of any disruption.
              </p>
              <a href="#" class="th-btn"
                >Read More<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="bg-shape">
                <img src="assets/img/bg/service_card_bg.png" alt="bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="bg-smoke"
      id="process-sec"
      data-bg-src="assets/img/bg/process_bg_1.png"
    >
      <div class="container space">
        <div class="title-area text-center">
          <span class="sub-title"
            ><div class="icon-masking me-2">
              <span
                class="mask-icon"
                data-mask-src="assets/img/theme-img/title_shape_2.svg"
              ></span>
              <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
            </div>
            WORK PROCESS</span
          >
          <h2 class="sec-title">
            How to work <span class="text-theme">it!</span>
          </h2>
        </div>
        <div class="process-card-area">
          <div class="process-line">
            <img src="assets/img/bg/process_line.svg" alt="line" />
          </div>
          <div class="row gy-40">
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">01</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_1.svg" alt="icon" />
                </div>
                <h2 class="box-title">Select a project</h2>
                <p class="process-card_text">
                Identify and choose the most suitable project based on business goals and resource availability.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">02</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_2.svg" alt="icon" />
                </div>
                <h2 class="box-title">Project analysis</h2>
                <p class="process-card_text">
                Conduct a thorough evaluation to understand project requirements, risks, and potential outcomes.

                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">03</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_3.svg" alt="icon" />
                </div>
                <h2 class="box-title">Plan Execute</h2>
                <p class="process-card_text">
                Develop a strategic plan and implement the necessary steps to achieve project goals efficiently.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">04</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_4.svg" alt="icon" />
                </div>
                <h2 class="box-title">Deliver result</h2>
                <p class="process-card_text">
                Ensure the timely delivery of project outcomes that meet quality standards and client expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Services