import React from 'react'

const WorkWithUs = () => {
  return (
    <div>
        <section class="cta-area9">
      <div class="container th-container4">
        <div class="row">
          <div class="col-lg-3">
            <div class="cta-feature_img th-anim">
              <img src="assets/img/normal/cta-1.png" alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="cta-title-area2">
              <div class="title-area mb-45 text-center">
                <span class="sub-title sub-title4">Work With Us</span>
                <h3 class="sec-title">
                  We Make Awesome IT Services For Your Newly Business
                </h3>
              </div>
              <div class="feature-area2">
                <a href="#" class="th-btn style8 style-radius"
                  >Start A Projects</a
                >
                <div class="feature-wrapper style2">
                  <div class="feature-icon">
                    <a href="tel:+2583258235"
                      ><i class="fa-solid fa-phone"></i
                    ></a>
                  </div>
                  <div class="media-body">
                    <p class="header-info_link">
                      <a href="tel:+2583258235">Call Us: 9876543210</a>
                    </p>
                    <span class="header-info_label">For any question</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="cta-feature_img th-anim">
              <img src="assets/img/normal/cta-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="bg-smoke"
      id="process-sec"
      data-bg-src="assets/img/bg/process_bg_1.png"
    >
      <div class="container space">
        <div class="title-area text-center">
          <span class="sub-title"
            ><div class="icon-masking me-2">
              <span
                class="mask-icon"
                data-mask-src="assets/img/theme-img/title_shape_2.svg"
              ></span>
              <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
            </div>
            WORK PROCESS</span
          >
          <h2 class="sec-title">
            How to work <span class="text-theme">it!</span>
          </h2>
        </div>
        <div class="process-card-area">
          <div class="process-line">
            <img src="assets/img/bg/process_line.svg" alt="line" />
          </div>
          <div class="row gy-40">
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">01</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_1.svg" alt="icon" />
                </div>
                <h2 class="box-title">Select a project</h2>
                <p class="process-card_text">
                Identify and choose the most suitable project based on business goals and resource availability.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">02</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_2.svg" alt="icon" />
                </div>
                <h2 class="box-title">Project analysis</h2>
                <p class="process-card_text">
                Conduct a thorough evaluation to understand project requirements, risks, and potential outcomes.

                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">03</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_3.svg" alt="icon" />
                </div>
                <h2 class="box-title">Plan Execute</h2>
                <p class="process-card_text">
                Develop a strategic plan and implement the necessary steps to achieve project goals efficiently.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 process-card-wrap">
              <div class="process-card">
                <div class="process-card_number">04</div>
                <div class="process-card_icon">
                  <img src="assets/img/icon/process_card_4.svg" alt="icon" />
                </div>
                <h2 class="box-title">Deliver result</h2>
                <p class="process-card_text">
                Ensure the timely delivery of project outcomes that meet quality standards and client expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default WorkWithUs