// import React from "react";

// const Header = () => {
//   return (
//     <>
//     <section>
//     <div>
//     <div class="th-menu-wrapper">
//       <div class="th-menu-area text-center">
//         <button class="th-menu-toggle"><i class="fal fa-times"></i></button>
//         <div class="mobile-logo">
//           <a class="icon-masking" href="/"
//             ><span data-mask-src="assets/img/logo.svg" class="mask-icon"></span
//             ><img src="assets/img/logo.svg" alt="Webteck"
//           /></a>
//         </div>
//         <div class="th-mobile-menu">
//           <ul>
//             <li class="menu-item-has-children mega-menu-wrap">
//               <a href="#">Home</a>
//               <ul class="mega-menu">
//                 <li>
//                   <a href="#">Multipage</a>
//                   <ul>
//                     <li><a href="/">Digital Agency</a></li>
//                     <li>
//                       <a href="home-web-development.html">Web Development</a>
//                     </li>
//                     <li>
//                       <a href="home-software-company.html">Software Company</a>
//                     </li>
//                     <li>
//                       <a href="home-software-company-2.html"
//                         >Software Company Style 2</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-startup-company.html">Startup Company</a>
//                     </li>
//                     <li><a href="home-it-solution.html">IT Solution</a></li>
//                     <li><a href="home-web-agency.html">Web Agency</a></li>
//                     <li><a href="home-startup.html">Home Startup</a></li>
//                     <li><a href="home-game-solution.html">Game Solution</a></li>
//                     <li>
//                       <a href="home-sass-marketing.html">Sass Marketing</a>
//                     </li>
//                     <li><a href="home-sass-landing.html">Sass Landing</a></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <a href="#">Multipage</a>
//                   <ul>
//                     <li>
//                       <a href="home-sass-landing-2.html"
//                         >Sass Landing Style 2</a
//                       >
//                     </li>
//                     <li><a href="home-app-landing.html">App Landing</a></li>
//                     <li><a href="home-ai-technology.html">AI Technology</a></li>
//                     <li>
//                       <a href="home-cyber-security.html">Cyber Security</a>
//                     </li>
//                     <li>
//                       <a href="home-cyber-security-2.html"
//                         >Cyber Security Style 2</a
//                       >
//                     </li>
//                     <li><a href="home-it-company.html">IT Company</a></li>
//                     <li>
//                       <a href="home-digital-marking.html">Digital Marking</a>
//                     </li>
//                     <li><a href="home-it-agency.html">IT Agency</a></li>
//                     <li><a href="home-crm.html">Home CRM</a></li>
//                     <li><a href="home-it-consulting.html">IT Consulting</a></li>
//                     <li><a href="home-help-desk.html">Home Help Desk</a></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <a href="#">Onepage</a>
//                   <ul>
//                     <li>
//                       <a href="home-digital-agency-op.html"
//                         >Digital Agency Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-web-development-op.html"
//                         >Web Development Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-software-company-op.html"
//                         >Software Company Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-software-company-2-op.html"
//                         >Software Company Style 2 Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-startup-company-op.html"
//                         >Startup Company Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-it-solution-op.html">IT Solution Onepage</a>
//                     </li>
//                     <li>
//                       <a href="home-web-agency-op.html">Web Agency Onepage</a>
//                     </li>
//                     <li>
//                       <a href="home-startup-op.html">Home Startup Onepage</a>
//                     </li>
//                     <li>
//                       <a href="home-game-solution-op.html"
//                         >Game Solution Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-sass-marketing-op.html"
//                         >Sass Marketing Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-sass-landing-op.html"
//                         >Sass Landing Onepage</a
//                       >
//                     </li>
//                   </ul>
//                 </li>
//                 <li>
//                   <a href="#">Onepage</a>
//                   <ul>
//                     <li>
//                       <a href="home-sass-landing-2-op.html"
//                         >Sass Landing Style 2 Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-app-landing-op.html">App Landing Onepage</a>
//                     </li>
//                     <li>
//                       <a href="home-ai-technology-op.html"
//                         >AI Technology Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-cyber-security-op.html"
//                         >Cyber Security Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-cyber-security-2-op.html"
//                         >Cyber Security Style 2 Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-it-company-op.html">IT Company Onepage</a>
//                     </li>
//                     <li>
//                       <a href="home-digital-marking-op.html"
//                         >Digital Marking Onepage</a
//                       >
//                     </li>
//                     <li>
//                       <a href="home-it-agency-op.html">IT Agency Onepage</a>
//                     </li>
//                     <li><a href="home-crm-op.html">Home CRM Onepage</a></li>
//                     <li>
//                       <a href="home-it-consulting-op.html"
//                         >IT Consulting Onepage</a
//                       >
//                     </li>
//                     <li><a href="home-help-desk-op.html">Home Help Desk</a></li>
//                   </ul>
//                 </li>
//               </ul>
//             </li>
//             <li><a href="about.html">About Us</a></li>
//             <li class="menu-item-has-children">
//               <a href="#">Services</a>
//               <ul class="sub-menu">
//                 <li><a href="service.html">Services</a></li>
//                 <li><a href="service-details.html">Services Details</a></li>
//               </ul>
//             </li>
//             <li class="menu-item-has-children">
//               <a href="#">Pages</a>
//               <ul class="sub-menu">
//                 <li class="menu-item-has-children">
//                   <a href="#">Shop</a>
//                   <ul class="sub-menu">
//                     <li><a href="shop.html">Shop</a></li>
//                     <li><a href="shop-details.html">Shop Details</a></li>
//                     <li><a href="cart.html">Cart Page</a></li>
//                     <li><a href="checkout.html">Checkout</a></li>
//                     <li><a href="wishlist.html">Wishlist</a></li>
//                   </ul>
//                 </li>
//                 <li><a href="team.html">Team</a></li>
//                 <li><a href="team-details.html">Team Details</a></li>
//                 <li><a href="project.html">Project</a></li>
//                 <li><a href="project-details.html">Project Details</a></li>
//                 <li><a href="gallery.html">Gallery</a></li>
//                 <li><a href="pricing.html">Pricing</a></li>
//                 <li><a href="faq.html">Faq Page</a></li>
//                 <li><a href="error.html">Error Page</a></li>
//               </ul>
//             </li>
//             <li class="menu-item-has-children">
//               <a href="#">Blog</a>
//               <ul class="sub-menu">
//                 <li><a href="blog.html">Blog</a></li>
//                 <li><a href="blog-details.html">Blog Details</a></li>
//               </ul>
//             </li>
//             <li><a href="contact.html">Contact</a></li>
//           </ul>
//         </div>
//       </div>
//     </div>
//     </div>
//     </section>
//     <section>
//     <header class="th-header header-layout10">
//       <div class="header-top">
//         <div class="container th-container4">
//           <div
//             class="row justify-content-center justify-content-lg-between align-items-center"
//           >
//             <div class="col-auto d-none d-md-block">
//               <div class="header-links">
//                 <ul>
//                   <li>
//                     <i class="fa-regular fa-phone"></i
//                     ><a href="tel:+25632542598">(+256) 3254 2598</a>
//                   </li>
//                   <li class="d-none d-xl-inline-block">
//                     <i class="fa-sharp fa-regular fa-location-dot"></i>
//                     <span>121 Melbourne VIC 300, Australia</span>
//                   </li>
//                   <li>
//                     <i class="fa-regular fa-envelope"></i
//                     ><a href="mailto:help@webteck.com">help@webteck.com</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             <div class="col-auto">
//               <div class="social-links">
//                 <span class="social-title">Follow Us On:</span>
//                 <a href="https://www.facebook.com/"
//                   ><i class="fab fa-facebook-f"></i
//                 ></a>
//                 <a href="https://www.twitter.com/"
//                   ><i class="fab fa-twitter"></i
//                 ></a>
//                 <a href="https://www.linkedin.com/"
//                   ><i class="fab fa-linkedin-in"></i
//                 ></a>
//                 <a href="https://www.instagram.com/"
//                   ><i class="fab fa-instagram"></i
//                 ></a>
//                 <a href="https://www.youtube.com/"
//                   ><i class="fab fa-youtube"></i
//                 ></a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="sticky-wrapper">
//         <div class="menu-area">
//           <div class="container th-container4">
//             <div class="row align-items-center justify-content-between">
//               <div class="col-auto">
//                 <div class="header-logo">
//                   <a class="icon-masking" href="/"
//                     >
//                     {/* <img src="assets/img/logo-small.svg" alt="Webteck"/> */}
//                     <img src="assets/img/zues-logo.png" alt="logo" width="200" height="75"/>
//                   </a>
//                 </div>
//               </div>
//               <div class="col-auto">
//                 <nav class="main-menu style2 d-none d-lg-inline-block">
//                   <ul>
//                     <li class=" mega-menu-wrap">
//                       <a href="/">Home</a>
//                     </li>
//                     <li><a href="/about-us">About Us</a></li>
//                     <li class="">
//                       <a href="/services">Services</a>
//                     </li>
//                     <li class="">
//                       {/* <a href="clients">Clients</a> */}
//                       {/* <ul class="sub-menu">
//                         <li><a href="blog.html">Blog</a></li>
//                         <li><a href="blog-details.html">Blog Details</a></li>
//                       </ul> */}
//                     </li>
//                     <li class="">
//                       <a href="projects">Projects</a>
//                     </li>
//                     <li><a href="/contacts">Contact</a></li>
//                   </ul>
//                 </nav>
//                 <button type="button" class="th-menu-toggle d-block d-lg-none">
//                   <i class="far fa-bars"></i>
//                 </button>
//               </div>
//               <div class="col-auto d-xl-block d-none">
//                 <div class="header-button">
//                   <a href="contacts" class="th-btn style8 style-radius"
//                     >Get in Touch</a
//                   >
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//     </section>
//     </>
//   );
// };

// export default Header;



import './Header.css'

import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  return (
    <>
      <header className="th-header header-layout10">
        {/* Navbar with fixed position at the top */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar shadow">
          <div className="container">
            {/* Logo */}
            <a className="navbar-brand" href="/">
              {/* <img src="assets/img/logo.svg" alt="Logo" width="200" height="75" /> */}
              <img src="assets/img/zues-logo.png" alt="logo" width="200" height="75"/>
            </a>
            {/* Navbar Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* Collapsible Navbar Content */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about-us">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/projects">Projects</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contacts">Contact</a>
                </li>
              </ul>
              {/* Get in Touch Button */}
              <a className="btn btn-primary ms-3" href="/contacts">
                Get in Touch
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

