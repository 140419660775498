import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import { Navigation } from "swiper";

const Team = () => {
  const teamMembers = [
    { name: "Honey Deep", role: "Chief Officer", img: "assets/img/team/team_4_1.jpg" },
    { name: "Mac Alexixe", role: "CEO, Founder", img: "assets/img/team/team_4_2.jpg" },
    { name: "Alex Julian", role: "Instructor", img: "assets/img/team/team_4_3.jpg" },
    { name: "Jenifer Lopez", role: "Instructor", img: "assets/img/team/team_4_4.jpg" },
    { name: "Henry Joseph", role: "Senior Instructor", img: "assets/img/team/team_4_1.jpg" },
  ];

  return (
    <section className="team-area6 space">
      <div className="container th-container4">
        <div className="title-area text-center">
          <span className="sub-title sub-title3">Team Members</span>
          <h2 className="sec-title">Meet Our Dedicated Team</h2>
        </div>
        <Swiper
        //   modules={[Navigation]}
          // navigation
          spaceBetween={30}
          breakpoints={{
            0: { slidesPerView: 1 },
            576: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
        >
          {teamMembers.map((member, index) => (
            <SwiperSlide key={index}>
              <div className="th-team team-card3">
                <div className="team-img">
                  <img src={member.img} alt={member.name} />
                </div>
                <div className="box-content">
                  <div className="social-links">
                    <a target="_blank" href="https://facebook.com">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a target="_blank" href="https://twitter.com">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a target="_blank" href="https://instagram.com">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" href="https://linkedin.com">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">
                      <a href="#">{member.name}</a>
                    </h3>
                    <span className="team-desig">{member.role}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Team;
