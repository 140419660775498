import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import AboutUs from './components/pages/AboutUs'
import Services from './components/pages/Services'
import Contacts from './components/pages/Contacts'
import Clients from './components/pages/Clients'
import Projects from './components/pages/Projects'

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/services' element={<Services/>}/>
        {/* <Route path='/clients' element={<Clients/>}/> */}
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/contacts' element={<Contacts/>}/>
      </Routes>
     
      <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App