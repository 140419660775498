import React from 'react'

const Services = () => {
  return (
    <div>
         <section class="service-area5 overflow-hidden space" id="service-sec">
      <div class="container th-container4">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="title-area text-center me-xl-5 ms-xl-5">
              <span class="sub-title sub-title4">Our Services</span>
              <h2 class="sec-title">Comprehensive IT Services</h2>
            </div>
          </div>
        </div>
        <div class="row gy-4 justify-content-between align-items-center">
          <div class="col-md-6 col-xl-3">
            <div class="service-box3">
              <div class="service-box3_content">
                <div class="service-box3_icon">
                  <img src="assets/img/icon/service_4_1.svg" alt="Icon" />
                </div>
                <h3 class="box-title">
                  <a href="#">Cyber Security Solution</a>
                </h3>
                <p class="service-box3_text">
                Protect your business with advanced cyber security solutions for threat detection, data protection, and compliance.
                </p>
                <a href="#" class="line-btn"
                  >Learn More<i class="fa-regular fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-6">
            <div class="service-box3_img">
              <img src="assets/img/service/service_box_7-4.jpg" alt="" />
              <div
                class="service-shape"
                data-mask-src="assets/img/shape/ser_shape_2.png"
                alt=""
              ></div>
              <div
                class="service-shape2"
                data-mask-src="assets/img/shape/ser_shape_3.png"
                alt=""
              ></div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="service-box3">
              <div class="service-box3_content">
                <div class="service-box3_icon">
                  <img src="assets/img/icon/service_4_2.svg" alt="Icon" />
                </div>
                <h3 class="box-title">
                  <a href="#">Cloud Integration</a>
                </h3>
                <p class="service-box3_text">
                  
Seamlessly connect your systems with scalable and secure cloud integration solutions.
                </p>
                <a href="#" class="line-btn"
                  >Learn More<i class="fa-regular fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="service-box3">
              <div class="service-box3_content">
                <div class="service-box3_icon">
                  <img src="assets/img/icon/service_4_3.svg" alt="Icon" />
                </div>
                <h3 class="box-title">
                  <a href="#">Managed IT Services</a>
                </h3>
                <p class="service-box3_text">
                Comprehensive managed IT services to optimise performance and ensure seamless operations.
                </p>
                <a href="#" class="line-btn"
                  >Learn More<i class="fa-regular fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="service-box3">
              <div class="service-box3_content">
                <div class="service-box3_icon">
                  <img src="assets/img/icon/service_4_4.svg" alt="Icon" />
                </div>
                <h3 class="box-title">
                  <a href="#">Software Development</a>
                </h3>
                <p class="service-box3_text">
                Custom software development solutions tailored to meet your business needs.
                </p>
                <a href="#" class="line-btn"
                  >Learn More<i class="fa-regular fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="service-box3">
              <div class="service-box3_content">
                <div class="service-box3_icon">
                  <img src="assets/img/icon/service_4_5.svg" alt="Icon" />
                </div>
                <h3 class="box-title">
                  <a href="#">Data Analytics</a>
                </h3>
                <p class="service-box3_text">
                Transform data into actionable insights with advanced analytics solutions.
                </p>
                <a href="#" class="line-btn"
                  >Learn More<i class="fa-regular fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="service-btn-area">
              <a
                href="#"
                class="th-btn service-btn text-capitalize"
                >View All Services<i class="fa-regular fa-arrow-right ms-2"></i
              ></a>
              <div class="ser-shape">
                <img src="assets/img/icon/circle.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Services