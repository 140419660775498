import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section>
      <div
        className="th-hero-wrapper hero-12"
        id="hero"
        style={{
          backgroundImage: "url('assets/img/bg/hero_bg_1.png')",
          backgroundSize: "cover",
        }}
      >
        <Slider {...settings}>
          <div>
            <div className="hero-inner">
              <div className="container th-container4">
                <div className="hero-style12">
                  <h1 className="hero-title" style={{fontWeight:'300',fontSize:'48px'}}>
                    Zeus Crew Made Simple
                    <span className="text-theme fw-normal"> Solutions,</span> Made Powerful
                  </h1>
                  <div className="btn-group">
                    <a href="#" className="th-btn style3 style-radius text-capitalize">
                      Get In Touch
                    </a>
                    <a href="#" className="th-btn blue-border style-radius text-capitalize">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-hero-img">
              <img src="assets/img/hero/hero_img_11_1.jpg" alt="Slide 1" />
            </div>
          </div>

          <div>
            <div className="hero-inner">
              <div className="container th-container4">
                <div className="hero-style12">
                  <h1 className="hero-title" style={{fontWeight:'300',fontSize:'48px'}}>
                    Perfect IT <span className="text-theme fw-normal">Solutions</span> For Your Business
                  </h1>
                  <div className="btn-group">
                    <a href="#" className="th-btn style3 style-radius text-capitalize">
                      Get In Touch
                    </a>
                    <a href="#" className="th-btn blue-border style-radius text-capitalize">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-hero-img">
              <img src="assets/img/hero/hero_img_11_2.jpg" alt="Slide 2" />
            </div>
          </div>

          <div>
            <div className="hero-inner">
              <div className="container th-container4">
                <div className="hero-style12">
                  <h1 className="hero-title" style={{fontWeight:'300',fontSize:'48px'}}>
                    Crafting Secure and Innovative IT
                    <span className="text-theme fw-normal">Solutions</span>
                  </h1>
                  <div className="btn-group">
                    <a href="#" className="th-btn style3 style-radius text-capitalize">
                      Get In Touch
                    </a>
                    <a href="#" className="th-btn blue-border style-radius text-capitalize">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-hero-img">
              <img src="assets/img/hero/hero_img_11_3.jpg" alt="Slide 3" />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
