import React from 'react'

const Counter = () => {
  return (
    <div>
           <div class="counter-area-8 overflow-hidden space-bottom">
      <div class="container th-container5">
        <div class="counter-card4-wrap">
          <div class="counter-card4">
            <div class="counter-card4_icon">
              <img src="assets/img/icon/counter_card_4_1.svg" alt="" />
            </div>
            <div class="media-body">
              <h3 class="box-number">
                <span class="counter-number">20</span>X
              </h3>
              <h6 class="counter-title">Return on investment</h6>
              <p class="counter-text mb-0">
                In which roasted parts of sentences fly into your mouth.
              </p>
            </div>
          </div>
          <div class="divider"></div>
          <div class="counter-card4">
            <div class="counter-card4_icon">
              <img src="assets/img/icon/counter_card_4_2.svg" alt="" />
            </div>
            <div class="media-body">
              <h3 class="box-number">
                <span class="counter-number">150</span>k+
              </h3>
              <h6 class="counter-title">Keywords optimization</h6>
              <p class="counter-text mb-0">
                keywords into online content, such as websites, blogs
              </p>
            </div>
          </div>
          <div class="divider"></div>
          <div class="counter-card4">
            <div class="counter-card4_icon">
              <img src="assets/img/icon/counter_card_4_3.svg" alt="" />
            </div>
            <div class="media-body">
              <h3 class="box-number">
                <span class="counter-number">10,000</span>+
              </h3>
              <h6 class="counter-title">Successful campaigns</h6>
              <p class="counter-text mb-0">
                Successful campaigns leverage strategic planning goals
              </p>
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Counter